import { memo } from "react";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import React from "react";
import withField from "../Field";

interface Props {
  placeholder?: string;
  error?: string;
  name?: string;
  value?: any;
  mask?: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (
    ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  customBlur?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  touched?: boolean;
  variant?: "standard" | "filled" | "outlined" | undefined;
  color?: string;
}

const _Input = ({
  placeholder,
  error,
  name,
  value,
  mask,
  onChange,
  onBlur,
  onFocus,
  customBlur,
  type,
  touched,
  variant = "outlined",
  color,
}: Props) => {
  const hasError = Boolean(error) && touched;

  return mask ? (
    <InputMask
      mask={mask}
      alwaysShowMask={false}
      onChange={onChange}
      onBlur={(ev) => {
        if (onBlur !== undefined) {
          onBlur(ev);
        }
        if (customBlur !== undefined) {
          customBlur(ev);
        }
      }}
      value={value || ""}
    >
      {(inputProps: any) => (
        <TextField
          {...inputProps}
          error={hasError}
          label={placeholder}
          name={name}
          variant={variant}
          size="medium"
          inputProps={{ style: { height: 56 } }}
          fullWidth
          helperText={hasError && error}
          type={type}
        />
      )}
    </InputMask>
  ) : (
    <TextField
      error={hasError}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value || ""}
      label={placeholder}
      name={name}
      variant={variant}
      size="medium"
      inputProps={{ style: { height: 56, color, borderColor: color } }}
      fullWidth
      helperText={hasError && error}
      type={type}
    />
  );
};

export const Input = memo(_Input);

export default withField(memo(_Input));
